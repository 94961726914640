<script>
  import { updateSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";

  import DetailsForm from "../../components/DetailsForm.svelte";
  import OrderSummary from "../../components/OrderSummary.svelte";
  import InstalmentAgreement from "../../components/InstalmentAgreement.svelte";

  export let scoped;
  const { countries, price, session, countryCode, accessibleStages } = scoped;
  const accessible = accessibleStages.includes("details");
  if (!accessible) $redirect("./package");

  function formSubmit({ customerDetails }) {
    updateSession(session, { customerDetails });
    $goto("./payment");
  }
</script>

{#if accessible}
  <OrderSummary {countries} {countryCode} {price} isOnline={true} />
  <DetailsForm
    {countries}
    {countryCode}
    {price}
    customerDetails={$session.customerDetails}
    {formSubmit}
    isMember={true}
  >
    <div slot="before">
      {#if price.variant.code === "monthly"}
        <InstalmentAgreement>
          I agree that by choosing to pay in 5 monthly instalments, my payment
          schedule must be maintained on time to keep continued login access to
          the online course materials, and when all payments are complete I will
          retain membership &amp; enjoy unrestricted lifetime access to this
          online course.
        </InstalmentAgreement>
      {/if}
    </div>
  </DetailsForm>
{/if}
