<script>
  import { updateSession, getRegion, getPrice } from "../../utils.js";
  import { goto, url } from "@roxi/routify";

  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import PriceDisplay from "../../components/PriceDisplay.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import VariantSelect from "../../components/VariantSelect.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";
  import ConsentModal from "../../components/ConsentModal.svelte";

  export let scoped;
  const {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;
  let isOpen = false;

  const region = getRegion(countries, countryCode);
  const regionalPrices = prices.filter((price) => price.region.code === region);
  const initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let product;
  product = initialPrice ? initialPrice.variant.product : null;

  let variant;
  variant = initialPrice ? initialPrice.variant : null;

  let price;
  $: price = product
    ? regionalPrices.find((price) => price.variant.id === variant.id)
    : null;

  let toggleCurrencyConverter;

  const productDetails = {
    bardic_online_offer: {
      description: "Online",
      blurb: `
      Access the full Bardic training course online with full audio-visual content,
      containing all 51 Gwersi in modules, access to resources online including:
      the Sacred Grove Booklet,
      the Bardic Companion,
      the Book of Ritual,
      8 Festival Ritual Booklets, and
      a Meditation and Pronunciation Guide.
      The online version of the course has audio contributions of music, storytelling and song from many contributors, including Loreena McKennitt, Robin Williamson, and Damh the Bard.
      `,
    },
  };

  let isConsentGiven = false;

  function formSubmit() {
    const consentModal = new ConsentModal({
      target: document.body,
      props: {
        isOpen: true,
      },
    });
    consentModal.$on("consent", (event) => {
      isConsentGiven = event.detail.agreed;
      consentModal.$destroy();
      if (isConsentGiven) {
        updateSession(session, {
          selectedPriceID: price.id,
          isConsentGiven: isConsentGiven,
          selectedPriceRegionCode: price.region.code,
        });
        $goto("./details");
      }
    });
  }
</script>

<form on:submit|preventDefault={formSubmit}>
  <section>
    <h2>Greetings!</h2>
    <p class="space-under">
      As you are an existing OBOD member, this offer is for access to the online
      Bardic Course
    </p>

    <hr />

    <p class="space-under">
      The online Bardic Course contains all the Gwersi modules, including both
      the original text and audio content, as well as additional audiovisual
      content. Your login details will be emailed to you upon completion of
      payment.
    </p>

    <ProductSelect
      prices={regionalPrices}
      details={productDetails}
      bind:product
    />
  </section>

  <VariantSelect prices={regionalPrices} bind:variant />

  <section class="links">
    <div class="change-location">
      <p>
        All our prices in GBP based on your
        {#if countryCodeIsSelected}
          selected
        {:else}
          detected
        {/if}
        location in: <CountryName {countries} {countryCode} />
      </p>
      <a href={$url("./country")}>&gt; Change my location</a>
    </div>

    {#if price}
      <div class="currency-converter">
        <p>Click here to see this price in another currency.</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >Currency Converter</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >
  </section>

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {countries}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <ContactUsForm bind:isOpen />

  <section class="btn-container">
    <div class="btn-container__inside">
      <PriceDisplay {price} />
      <button disabled={!price} class="btn btn--primary">Next</button>
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .space-under {
    margin-bottom: 1rem;
  }

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  hr {
    margin: 1rem 0;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
